function App() {
  return (
    <div className="h-screen flex items-center justify-center bg-black-primary">
      <header>
       <h1 className="text-white-primary">Moises Nunez</h1>
      </header>
    </div>
  );
}

export default App;
